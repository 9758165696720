import React from 'react';

export default function Proceedings(props) {

    return (
        <div className='base-page'>
            <h4 className='heading'>Proceedings</h4>
            <p>No news to share yet! Check back after the workshop to view materials from the event.</p>

            <p><b>Check out <a className='link' href='/#/panoptic'>PANOPTIC™</a> for more information on MITRE's privacy threat model.
            </b></p>
            {/* <h4 className='heading'>Workshop Summary</h4>
            <div class='paper'>
                <h5>
                <a href={workshop_summary} download="SOUPS-WPTM-2023-Summary.pdf">
                Evolving Privacy Threat Modeling: Identifying and Addressing Risks in Data-Centric Environments</a>
                </h5>
                <h6>Samantha Katcher, MITRE</h6>
                <h6>Shelby Slotter, MITRE</h6>
                <h6>Stuart Shapiro, MITRE</h6>
            </div>
            <h4 className='heading new-section'>Privacy Threat Models</h4>
            <div class='paper'>
                <h5>
                <a href={panoptic_slides} download="PANOPTIC-update-slides.pdf">
                MITRE PANOPTIC™</a>
                </h5>
                <h6>Stuart Shapiro, MITRE</h6>
            </div>
            <div class='paper'>
                <h5>
                <a href={linddun_slides} download="LINDDUN4-slides.pdf">
                LINDDUN4</a>
                </h5>
                <h6>Laurens Sion, LINDDUN</h6>
            </div>
            <h4 className='heading new-section'>Presentations</h4>
            <div class='paper'>
                <h5>Keynote</h5>
                <h5>
                <a href={keynote_slides} download="META-keynote-slides.pdf">
                Better Together: Operationalizing Privacy Threat Models</a>
                </h5>
                <h6>Zach Miller, Meta</h6>
            </div>
            <div class='paper'>
                <h5>Presentation 1</h5>
                <h5>
                <a href={katcher_slides} download="Katcher-slides.pdf">
                Privacy Threat Modeling for Medical Devices: A Case Study</a>
                </h5>
                <h6>Samantha Katcher, MITRE</h6>
            </div>
            <div class='paper'>
                <h5>Presentation 2</h5>
                <h5>
                <a href={garg_slides} download="Garg-slides.pdf">
                Models of Applied Privacy (MAP): A Persona-based Approach to Privacy Threat Modeling</a>
                </h5>
                <h6>Jayati Dev, COMCAST
                <br />Bahman Rashidi, COMCAST
                <br />Vaibhav Garg, COMCAST</h6>
            </div>
            <div class='paper'>
                <h5>Presentation 3</h5>
                <h5>
                <a href={shan_slides} download="Shan-slides.pdf">
                Misalignments Between User and Developer Privacy Threat Models</a>
                </h5>
                <h6>Tianyi Shan, UC San Diego
                <br />Mary Anne Smart, UC San Diego
                <br />Jay Jhaveri, UC San Diego
                <br />Jessalyn Wang, UC San Diego
                <br />Kristen Vaccaro, UC San Diego</h6>
            </div> */}
        </div>
    )
}