import React, { useState } from 'react';
import {Container, Navbar, Nav} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import logo from './soups2024.png';

export default function NavBar(props) {

    // auto-exand/close mobile navbar menu on selection
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
        <Navbar className="navbar nav-pills" collapseOnSelect expand="lg" variant="dark" fixed="top" expanded={expanded}>
            <Container>
            <Navbar.Brand className='nav-brand'>
                <img
                    alt=""
                    src={logo}
                    width="40"
                    height="40"
                    className="d-inline-block"
                />{' '}
                SOUPS '24 - WPTM
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="responsive-navbar-nav" aria-labelledby="responsive-navbar-nav" placement="end">
                <Nav className='ms-auto'>
                    <a className="nav-link" href="https://ptmworkshop.gitlab.io/wptm22">WPTM 22</a>
                    <a className="nav-link" href="https://ptmworkshop.gitlab.io/wptm23">WPTM 23</a>
                    <div className="pipe"></div>
                    <Nav.Link className="nav-link" as={Link} to={"/"} onClick={() => setExpanded(false)}>Overview</Nav.Link>
                    <Nav.Link className="nav-link" as={Link} to={"/program"} onClick={() => setExpanded(false)}>Program</Nav.Link>
                    <Nav.Link className="nav-link" as={Link} to={"/attending"} onClick={() => setExpanded(false)}>Attending</Nav.Link>
                    <Nav.Link className="nav-link" as={Link} to={"/submissions"} onClick={() => setExpanded(false)}>Submissions</Nav.Link>
                    <Nav.Link className="nav-link" as={Link} to={"/proceedings"} onClick={() => setExpanded(false)}>Proceedings</Nav.Link>
                    <Nav.Link className="nav-link" as={Link} to={"/panoptic"} onClick={() => setExpanded(false)}>PANOPTIC™</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
            
        </Navbar>

        <Outlet />
        </div>
    )
}