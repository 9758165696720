import React from 'react';
import Agenda from './Agenda';

export default function Program(props) {

    let linddun = ' is a privacy threat modeling methodology that supports analysts in systematically eliciting and mitigating privacy threats in software architectures. LINDDUN provides support to guide you through the threat modeling process in a structured way. In addition, LINDDUN provides privacy knowledge support to enable non-privacy experts to reason about privacy threats. LINDDUN is a mnemonic for the privacy threat categories it supports: Linking, Identifying, Non-repudiation, Detecting, Data disclosure, Unawareness and Unintervenability, and Non-compliance.';
    let panoptic1 = 'MITRE PANOPTIC™, the Pattern and Action Nomenclature Of Privacy Threats In Context, is a publicly-available privacy threat taxonomy for breaking down and describing privacy attacks against individuals and groups of individuals. PANOPTIC was generated from the bottom up based on hundreds of real-world privacy attacks. It can be used for system and environmental privacy threat assessment, holistic privacy risk modeling, and privacy red teaming.';

    return (
        <div className='base-page'>
            <h4 className='heading'>Program</h4>
            <div className='table'>
                <Agenda />
            </div>
            <div className='new-section'>
                <h4 className='heading'>About the LINDDUN Privacy Threat Model</h4>
                <p>
                <a className='link' href='https://www.linddun.org' target='_blank' rel="noopener noreferrer">LINDDUN</a>
                {linddun}
                </p>
            </div>
            <div className='new-section'>
            <h4 className='heading'>About the MITRE PANOPTIC<sup>TM</sup> Privacy Threat Taxonomy</h4>
                <p> {panoptic1} Check out <a className='link' href='/#/panoptic'>PANOPTIC™</a> for more information.
                </p>
            </div>
        </div>
    )
}