import React from 'react';
import panoptic_v1 from "../Static/PANOPTIC-Taxonomy-v1-Publicly-Released.xlsx";
import panoptic_v2 from "../Static/PANOPTIC-Taxonomy-v2-Publicly-Released.xlsx";
import tutorial_slides_v1 from "../Static/PANOPTIC-v1-Tutorial-Slides.pptx";
import tutorial_pdf_v1 from "../Static/PANOPTIC-v1-Tutorial-PDF.pdf";
import tutorial_slides_v2 from "../Static/PANOPTIC-v2-Tutorial-Slides.pptx";
import tutorial_pdf_v2 from "../Static/PANOPTIC-v2-Tutorial-PDF.pdf";
import panoptic_mtr from "../Static/PANOPTIC-MTR-v1-Publicly-Released.pdf";
import panoptic_factsheet from "../Static/PANOPTIC-factsheet.pdf";

export default function PANOPTIC(props) {

    return (
        <div className='base-page'>
            <h4 className='heading new-section'>PANOPTIC™ Privacy Threat Model</h4>
            <hr style={{marginTop:'25px', marginBottom:'25px'}}></hr>
            <p>MITRE PANOPTIC™, the Pattern and Action Nomenclature Of Privacy Threats In Context, is a publicly available privacy threat taxonomy for breaking down and describing privacy attacks against individuals and groups of individuals. PANOPTIC was generated from the bottom up based on over 300 real-world privacy attacks drawn from Federal Trade Commission (FTC) and Federal Communications Commission (FCC) case files, New York Times articles, and Reddit posts. It can be used for system and environmental privacy threat assessment, holistic privacy risk modeling, and privacy red teaming. Further improvements to PANOPTIC based on GDPR cases are in process and updated versions will be posted later in the year. </p>
            <p>Questions or feedback can be sent to <a className='link' href='mailto:panoptic@mitre.org'>panoptic@mitre.org</a>.</p>
            <div class='paper'>
                <h5>
                <a href={panoptic_mtr} download="PANOPTIC-MTR.pdf">PANOPTIC™ MITRE Technical Report</a>
                </h5>
                <h6>Stuart Shapiro, Cara Bloom, Ben Ballard, Shelby Slotter, Mark Paes, Julie McEwen, Ryan Xu, and Samantha Katcher</h6>
            </div>
            <div class='paper'>
                <h5>
                <a href={panoptic_factsheet} download="PANOPTIC-factsheet.pdf">
                PANOPTIC™ Factsheet</a>
                </h5>
                <h6>Stuart Shapiro, Julie McEwen, Cara Bloom, Ben Ballard, Shelby Slotter, Samantha Katcher, Mark Paes, and Ryan Xu</h6>
            </div>
            <div class='paper'>
                <h5>PANOPTIC™ Version 2</h5>
                <h6>Stuart Shapiro, Julie McEwen, Cara Bloom, Ben Ballard, Shelby Slotter, Samantha Katcher, Mark Paes, and Ryan Xu</h6>
                <p>
                    <a className="link" href={panoptic_v2} download="PANOPTIC-V2.xlsx">PANOPTIC™ V2 Workbook</a>
                    <br></br>
                    <b>PANOPTIC™ V2 Tutorial -&nbsp;</b>
                    <a className="link" href={tutorial_slides_v2} download="PANOPTIC-V2-tutorial-slides.pptx">
                    Slides</a> |&nbsp;
                    <a className="link" href={tutorial_pdf_v2} download="PANOPTIC-V2-tutorial-pdf.pdf">
                    PDF</a>
                </p>
            </div>
            <div class='paper'>
                <h5 className='heading'>[DEPRECATED]</h5>
                <h5>PANOPTIC™ Version 1</h5>
                <h6>Stuart Shapiro, Julie McEwen, Cara Bloom, Ben Ballard, Shelby Slotter, Samantha Katcher, Mark Paes, and Ryan Xu</h6>
                <p>
                    <a className="link" href={panoptic_v1} download="PANOPTIC-V1.xlsx">PANOPTIC™ V1 Workbook</a>
                    <br></br>
                    <b>PANOPTIC™ V1 Tutorial -&nbsp;</b>
                    <a className="link" href={tutorial_slides_v1} download="PANOPTIC-V1-tutorial-slides.pptx">
                    Slides</a> |&nbsp;
                    <a className="link" href={tutorial_pdf_v1} download="PANOPTIC-V1-tutorial-pdf.pdf">
                    PDF</a>
                </p>
                
            </div>
        </div>
    )
}
