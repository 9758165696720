import React from 'react';
import wptm_cfp from "../Static/SOUPS24-WPTM-CFP.pdf";

export default function Landing(props) {

    let intro1 = 'The Workshop on Privacy Threat Modeling seeks participation in the form of research findings, new ideas, and constructive feedback. The purpose of the workshop is to bring together researchers, practitioners, industry specialists, and government representatives to collaborate on the topic of privacy threat modeling. While aspects of privacy risk modeling are relatively well developed, such as constructions of privacy harms, there has been insufficient discussion around approaches to modeling privacy threats, broadly construed. A holistic approach to representing privacy threats could inform privacy risk models and provide a common lexicon to accelerate conversations in the privacy community.';
    let intro2 = 'The workshop will include an informative session and a collaborative session. The informative session will include a keynote, brief updates on prominent existing privacy threat models, including MITRE PANOPTIC™ and LINDDUN, and presentations selected from participant submissions. In the collaborative session we will discuss the role of threat modeling in environmental privacy threat assessment.'
    let intro3 = 'Broadly, we hope this workshop will continue to forge new relationships across the privacy community around the topic of privacy threats, shape and guide the development of new and existing privacy threat models, and stimulate further research into privacy threats by providing an informative baseline on what privacy threat modeling is, as well as knowledge about the current state of the field, and ideas about operationalizing privacy threat models.'
    let topics = ['Definitions of a privacy incident, attack, threat, and breach', 
                'Distinctions between privacy threats, privacy harms, and privacy vulnerabilities', 
                'Describing or categorizing privacy threats, including taxonomies or ontologies for privacy incidents, attacks, threats, and breaches',
                'Applicability and limitations of security threat modeling techniques for privacy',
                'Integration of threat models in privacy risk models and risk management',
                'Privacy threat-informed defense',
                'Qualitative versus quantitative threat modeling',
                'Trade-offs between specific and general models',
                'Operationalizing privacy threat models',
                'Privacy threat case studies'];
    let contact1 = 'Please email  '
    let contact2 = ' to be added to our WPTM listserv.';
    

    return (
        <div className='base-page'>
            <h1 className='workshop-title'>The 3<sup>rd</sup> Workshop on Privacy Threat Modeling (WPTM)</h1>
            <h3 className='date-time-location'>Thursday, August 8th, 2024 | 11AM-2PM EDT | Virtual event</h3>
            <div className='intro-pars'>
                <p>{intro1}</p>
                <p>{intro2}</p>
                <p>{intro3}</p>
                <p>Topics of interest include:</p>
                <ul className='bulleted-list'>
                {topics.map((topic, index) => (
                    <li>{topic}</li>
                ))}
                </ul>
            </div>
            <div className='new-section'>
                <h4 className='heading'>Important Dates</h4>
                <p>Wednesday, May 1, 2024 – Submissions open for presentation proposals</p>
                <p><b>[EXTENDED] </b>Tuesday, June 4, 2024 23:59 AOE – Submission deadline</p>
                <p><b>[EXTENDED] </b>Tuesday, June 18, 2024 – Decisions announced</p>
                <p>Monday, August 5, 2024 5PM EDT – Workshop registration deadline</p>
                <p>Thursday, August 8, 2024 – The 3<sup>rd</sup> Workshop on Privacy Threat Modeling</p>
            </div>
            <div className='new-section'>
                <p><b>View the WPTM Call for Participation (CFP): <a className='link' href={wptm_cfp} download="SOUPS24-WPTM-CFP.pdf">WPTM 2024 CFP</a></b></p>
            </div>
            <div className='new-section'>
                <p><b>{contact1}
                <a className='link' href='mailto:PTMworkshop@mitre.org'>PTMworkshop@mitre.org</a>
                {contact2}</b></p>
            </div>
        </div>
    )
}