import React from 'react';

export default function Submissions(props) {
    // let sub_open = 'The submission portal is now open:';
    let sub1 = 'We are soliciting short presentation proposals (1 page using the SOUPS formatting template, found '
    let sub2 = ') for 15-minute talks related to privacy attacks, threats, and threat modeling. These may take the form of:'
    let subList = ['Positions, arguments, or perspectives',
                    'Proposals of processes, methodologies, or solutions',
                    'Novel ideas',
                    'Previously published results',
                    'Works in progress',
                    'Case studies']
    let sub3 = 'Submissions should include author names and affiliations. Workshop presentation abstracts and slide decks will be posted to ';
    let sub4 = ' following the workshop. These submissions will not be considered “published” work and should not preclude publication or presentation elsewhere.'
    let sub5 = 'Please email ';
    let sub6 = ' with questions.';
    let reg1 ='Registration is required for workshop attendance! Please see ';
    let reg2 = ' for more information and to register.';
    let sub_close = 'The submission portal has closed.'
    
    return (
        <div className='base-page'>
            <div className='submission-details'>
                <h4 className='heading'>Submissions</h4>

                <p><b>
                    {reg1}
                    <a className='link' href='/#/attending'>Attending</a>
                    {reg2}
                </b></p>

                <hr style={{marginTop:'25px', marginBottom:'25px'}}></hr>

                {/* Submission info */}
                <p><b>{sub_close}</b></p>

                <p> {sub1}
                <a className='link' href='https://www.usenix.org/conference/soups2024/call-for-papers' target='_blank' rel="noopener noreferrer">here</a>
                {sub2}
                </p>
                <ul className='bulleted-list'>
                {subList.map((subType, index) => (
                    <li>{subType}</li>
                ))}
                </ul>
                <p>{sub3}
                <a className='link' href='/#/proceedings'>Proceedings</a>
                {sub4}
                </p>

                {/* <ul style={{paddingLeft:'0px'}}>
                    <li style={{display:'inline-block'}}>
                        <button className='submit' onClick={()=> window.open("https://wptm2024.usenix.hotcrp.com/", "_blank", "noopener, noreferrer")}>
                            Submit Proposal
                        </button>
                    </li>
                </ul> */}

                <p>{sub5}
                <a className='link' href='mailto:PTMworkshop@mitre.org'>PTMworkshop@mitre.org</a>
                {sub6}
                </p>
            </div>
            <div className='new-section'>
                <h4 className='heading'>Program Committee</h4>
                <p>Cara Bloom, Netflix</p>
                <p>Jason Cronk, EnterPrivacy</p>
                <p>Stuart Shapiro, MITRE</p>
                <p>Laurens Sion, KU Leuven</p>
            </div>
            <div className='new-section'>
            <h4 className='heading'>Important Dates</h4>
                <p>Wednesday, May 1, 2024 – Submissions open for presentation proposals</p>
                <p><b>[EXTENDED] </b>Tuesday, June 4, 2024 23:59 AOE – Submission deadline</p>
                <p><b>[EXTENDED] </b>Tuesday, June 18, 2024 – Decisions announced</p>
                <p>Monday, August 5, 2024 5PM EDT – Workshop registration deadline</p>
                <p>Thursday, August 8, 2024 – The 3<sup>rd</sup> Workshop on Privacy Threat Modeling</p>
            </div>
        </div>
    )
}