import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from './Components/NavBar';
import Landing from './Components/Landing'
import Program from './Components/Program';
import Attending from './Components/Attending';
import Submissions from './Components/Submissions';
import Proceedings from './Components/Proceedings';
import PANOPTIC from './Components/PANOPTIC';

function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<NavBar/>}>
          <Route index element={<Landing/>} />
          <Route path="program" element={<Program/>} />
          <Route path="attending" element={<Attending/>} />
          <Route path="submissions" element={<Submissions/>} />
          <Route path="proceedings" element={<Proceedings/>} />
          <Route path="panoptic" element={<PANOPTIC/>} />
          <Route path='/wptm22' component={() => {
            window.location.href = 'http://localhost:3001';
            return null;
          }}/>
          <Route path='/wptm23' component={() => {
            window.location.href = 'http://localhost:3002';
            return null;
          }}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
