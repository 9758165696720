import React from 'react';

export default function Attending(props) {
    let attend1 = 'The Workshop on Privacy Threat Modeling will be held in conjunction with the ';
    let attend2 = ', August 11-13 in Philadelphia, PA. ';
    let attend3 = 'You are not required to register for SOUPS to attend the workshop.';
    let attend4 = "There is no cost to attend this year's workshop.";
    let attend5 = 'The workshop will take place ';
    let attend6 = 'in a virtual format on Thursday, August 8th from 11AM-2PM EDT.';
    // let attend7 = 'Attendees may submit a 1-page presentation proposal to be considered for a formal speaking role. Please see ';
    // let attend8 = ' for details.';
    // let attend9 = 'We are pleased to announce that we have modified the workshop format to accommodate more participants and have re-opened registration.';
    let reg_open = 'Workshop registration is open until Monday, August 5 at 5PM EDT.';
    let reg_note = 'UPDATE: ' /*'Please note that for logistical reasons registration will be capped at 60 attendees.'*/;
    // let reg_close = 'UPDATE: With apologies for any disappointment, due to overwhelming response, we have suspended workshop registration and are not currently accepting additional attendees.';
    let reg_note2 = 'We are pleased to announce that we have modified the workshop format to accomodate more participants and have re-opened registration.';

    return (
        <div className='base-page'>
            <div className='attending-details'>
                <h4 className='heading'>Attending</h4>
                <p><b>{reg_open}</b></p>
                <p><b>{reg_note}</b>{reg_note2}</p>
                <hr style={{marginTop:'25px', marginBottom:'25px'}}></hr>

                <p>{attend1} 
                <a className='link' href='https://www.usenix.org/conference/soups2024' target='_blank' rel="noopener noreferrer">Symposium on Usable Privacy and Security (SOUPS) 2024</a>
                {attend2}
                </p>

                <p>{attend3}&nbsp;{attend4}</p>
                <div>
                    <p>{attend5} 
                    <b>{attend6}</b></p>
                </div>
           

                {/* Registration button */}
                <ul style={{paddingLeft:'0px'}}>
                    <li style={{display:'inline-block'}}>
                        <button className='submit' onClick={()=> window.open("https://forms.office.com/g/xfUkGjXXg0", "_blank", "noopener, noreferrer")}>
                            Register
                        </button>
                    </li>
                </ul>

                {/* <p>{attend7} */}
                <a className='link' href='/#/submissions'>Submissions</a>
             


            </div>

            <div className='new-section'>
                <h4 className='heading'>Important Dates</h4>
                <p>Wednesday, May 1, 2024 – Submissions open for presentation proposals</p>
                <p><b>[EXTENDED] </b>Tuesday, June 4, 2024 23:59 AOE – Submission deadline</p>
                <p><b>[EXTENDED] </b>Tuesday, June 18, 2024 – Decisions announced </p>
                <p>Monday, August 5, 2024 5PM EDT – Workshop registration deadline</p>
                <p>Thursday, August 8, 2024 – The 3<sup>rd</sup> Workshop on Privacy Threat Modeling</p>
            </div>
        </div>
    )
}
