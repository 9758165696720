import React from "react";
import abstract_1 from "../Static/wptm2024-paper1.pdf";
import abstract_2 from "../Static/wptm2024-paper2.pdf";
import abstract_3 from "../Static/wptm2024-paper3.pdf";

export default function Agenda(props) {

    return (
        <div>
          <table style={{border: 'solid 1px #990099', width: '100%' }}>
            <thead style={{borderBottom: 'solid 2px #990099'}}>
              <th>Time (EDT)</th>
              <th>Activity</th>
            </thead>
            <tr>
              <td><b>11:00-11:05</b></td>
              <td><b>Introduction and welcome</b></td>
            </tr>
            <tr>
              <td><b>11:05-11:40</b></td>
              <td><b>Keynote and Q&amp;A</b>
                <br></br>
                Kim Wuyts, PwC Belgium
                <br></br>
                "Growing Pains: The Teenage Years of Privacy Threat Modeling"
              </td>
            </tr>
            <tr>
              <td><b>11:40-12:10</b></td>
              <td><b>Threat model updates</b>
                  <br></br>
                  11:40-11:50 LINDDUN
                  <br></br>
                  11:50-12:00 xCompass (formerly MAP)
                  <br></br>
                  12:00-12:10 MITRE PANOPTIC™
              </td>
            </tr>
            <tr>
              <td><b>12:10-12:55</b></td>
              <td><b>Presentations and Q&amp;A</b>
                <br></br>
                12:10-12:25&nbsp; 
                <a className="link" href={abstract_1} download="Privacy-Threats-in-Online-Advertising.pdf">“Privacy Threats in Online Advertising”</a>
                <br></br>
                12:25-12:40&nbsp; 
                <a className="link" href={abstract_2} download="UsersFirst.pdf">“UsersFirst: A User-Centric Threat Modeling Framework for Notice &amp; Choice”</a>
                <br></br>
                12:40-12:55&nbsp;  
                <a className="link" href={abstract_3} download="Eliciting-and-Mitigating-Interdependent-Privacy-Threats.pdf">“Eliciting and mitigating interdependent privacy threats with LINDDUN”</a>
              </td>
            </tr>
            <tr>
              <td><b>12:55-1:05</b></td>
              <td><b>Break</b></td>
            </tr>
            <tr>
              <td><b>1:05-2:00</b></td>
              <td><b>Forum on Open Problems</b></td>
            </tr>
            <tr>
              <td><b>2:00</b></td>
              <td><b>Closing</b></td>
            </tr>
          </table>
        </div>
    )
}